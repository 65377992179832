/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import useNFT from '../../Contexts/NFT/useNFT';
import { getAllNFTLit, getFeatureNFTList } from '../../lib/NFT';
import { ChipSelect } from '../FormsElements/RadioButton/ChipsSelecte/ChipSelect';
import styles from './styles.module.scss';
import SelectFilter from '../FormsElements/SelectFilter';
import UseAuth from '../../Contexts/Auth/useAuth';
import { getDeviceId } from '../../Hooks/getDeviceId';

const CategoryBtns = ({ className, ...props }) => {
    const { authState } = UseAuth();

    const cookies = new Cookies();
    const { nftState, setNFTCatagory, updateTopRated, updateExplore } =
        useNFT();
    const getNftData = async (value, sort = null) => {
        let params = {};
        let response;
        if (props.section === 'top_rated') {
            params = {
                key: 'top_rated',
                category: value.toLowerCase(),
                page_no: 0,
                page_size: 12,
                device_id: getDeviceId(),
            };
            response = await getFeatureNFTList(params);
            if (response?.status === 200) {
                updateTopRated(response.data.data);
            }
        } else {
            params = {
                category: value.toLowerCase(),
                page_no: 0,
                page_size: 12,
                sort: sort,
                device_id: getDeviceId(),
            };
            const res = await getAllNFTLit(params);
            if (res?.status === 200) {
                updateExplore(res.data);
            }
        }
    };
    useEffect(() => {
        setNFTCatagory('ALL');
        if (cookies.get('categoryType')) {
            let selectedCatagory = 'ALL';
            selectedCatagory = cookies.get('categoryType') ?? 'ALL';
            getNftData('ALL');
        }
    }, []);
    return (
        <div className={`${styles.categories} ${className}`}>
            <ChipSelect
                label="All"
                value="ALL"
                selectedValue={nftState.catagorySelected}
                labelcss={`${styles.radioButtonCss}`}
                onChange={(value) => {
                    setNFTCatagory(value);
                    cookies.set('categoryType', value, { maxAge: 86400 });
                    getNftData(value, props.selectedSort);
                }}
                iconUrl="/assets/vectors/Vector_cat_all.svg"
                iconType="svg"
                iconHeight="20px"
                iconWidth="20px"
            />
            <ChipSelect
                label="Art"
                value="ART"
                selectedValue={nftState.catagorySelected}
                labelcss={`${styles.radioButtonCss}`}
                onChange={(value) => {
                    setNFTCatagory(value);
                    cookies.set('categoryType', value, { maxAge: 86400 });
                    getNftData(value, props.selectedSort);
                }}
                iconUrl="/assets/vectors/Vector_cat_art.svg"
                iconType="svg"
                iconHeight="20px"
                iconWidth="20px"
            />
            <ChipSelect
                label="Music"
                value="MUSIC"
                selectedValue={nftState.catagorySelected}
                labelcss={`${styles.radioButtonCss}`}
                onChange={(value) => {
                    cookies.set('categoryType', value, { maxAge: 86400 });
                    setNFTCatagory(value);
                    getNftData(value, props.selectedSort);
                }}
                iconUrl="/assets/vectors/Vector_cat_music.svg"
                iconType="svg"
                iconHeight="20px"
                iconWidth="20px"
            />
            <ChipSelect
                label="Video"
                value="VIDEO"
                selectedValue={nftState.catagorySelected}
                labelcss={`${styles.radioButtonCss}`}
                onChange={(value) => {
                    setNFTCatagory(value);
                    cookies.set('categoryType', value, { maxAge: 86400 });
                    getNftData(value, props.selectedSort);
                }}
                iconUrl="/assets/vectors/Vector_cat_video.svg"
                iconType="svg"
                iconHeight="20px"
                iconWidth="20px"
            />
            <ChipSelect
                label="Photography"
                value="PHOTOGRAPHY"
                selectedValue={nftState.catagorySelected}
                labelcss={`${styles.radioButtonCss}`}
                onChange={(value) => {
                    setNFTCatagory(value);
                    cookies.set('categoryType', value, { maxAge: 86400 });
                    getNftData(value, props.selectedSort);
                }}
                iconUrl="/assets/vectors/Vector_cat_photo.svg"
                iconType="svg"
                iconHeight="22px"
                iconWidth="22px"
            />
            <ChipSelect
                label="Gallery"
                value="GALLERY"
                selectedValue={nftState.catagorySelected}
                labelcss={`${styles.radioButtonCss}`}
                onChange={(value) => {
                    setNFTCatagory(value);
                    cookies.set('categoryType', value, { maxAge: 86400 });
                    getNftData(value, props.selectedSort);
                }}
                iconUrl="/assets/vectors/Vector_cat_gallery.svg"
                iconType="svg"
                iconHeight="20px"
                iconWidth="20px"
            />
            <ChipSelect
                label="Giveaway"
                value="GIVEAWAY"
                selectedValue={nftState.catagorySelected}
                labelcss={`${styles.radioButtonCss}`}
                onChange={(value) => {
                    setNFTCatagory(value);
                    cookies.set('categoryType', value, { maxAge: 86400 });
                    getNftData(value, props.selectedSort);
                }}
                iconUrl="/assets/vectors/giveaway.png"
                iconType="png"
                iconHeight="20px"
                iconWidth="20px"
            />
            {props?.isFilter && (
                <SelectFilter
                    options={[
                        {
                            key: 'Random',
                            value: 1,
                        },
                        {
                            key: 'New NFTs First',
                            value: 2,
                        },
                        {
                            key: 'Old NFTs First',
                            value: 3,
                        },
                        {
                            key: 'Price Low - High',
                            value: 4,
                        },
                        {
                            key: 'Price High - Low',
                            value: 5,
                        },
                    ]}
                    label={`Sort By Filter`}
                    placeHolder={'Sort By Filter'}
                    id="filter"
                    onChangeHandler={(value) => {
                        props.onChangeFilter(value);
                        getNftData(nftState.catagorySelected, value);
                    }}
                    defaultOption="Sort By"
                    currentValue={props.selectedSort}
                />
            )}
        </div>
    );
};

export default CategoryBtns;
