// import { useWindowResize } from "hooks/useWindowResize";
import React from "react";
import { Form } from "react-bootstrap";
import styles from "./styles.module.scss";

const SelectFilter = ({
  defaultSelectValue,
  currentValue,
  onChangeHandler,
  label,
  options,
  size,
  value,
  disabled,
  category,
  bit,
  ...props
}) => {
  const renderOptions = (options) => {
    return options.map((item, index) => (
        <option key={index} label={item.key} value={item.value} defaultValue={item === value} disabled={bit === '1' && category?.length === 1 && category?.includes('Giveaway') && (item.value === 5 || item.value === 6)}>{item.key}</option>
    ));
  };

  return (
    <div className={`${styles.rst__clr} ${styles.parentDiv} selectInput position-relative`}>
      {/* <Form.Select className={`${styles.select} ${props.className}`} aria-label={defaultSelectValue}>
                <option>--- Select ---</option>
                {renderOptions}
            </Form.Select> */}
      {/* 
      <div className={`d-flex`}>
        <Label>{label}</Label>
        {props.seconLabel && (
          <Label className={`${styles.labeltwo} ml-2`} htmlFor={props.id}>
            ({props.seconLabel})
          </Label>
        )}
      </div> */}

      <Form.Control
        value={currentValue}
        onChange={(e) => onChangeHandler(e.target.value)}
        className={`Inputstyle customStyle ${styles.customStyle} ${props.className}`}
        label={`--- Select ---`}
        as="select"
        size={size ?? "lg"}
      // custom
      >
        <option value="" key={-1}>
          {props?.defaultOption ? props.defaultOption : "--- Select ---"}
        </option>
        {renderOptions(options, value)}
      </Form.Control>
      {/* <SVG
        src="/assets/vectors/Vector_right_arror.svg"
        width="14px"
        height="14px"
        className={`${styles.selectIcon}`}
      /> */}
    </div>
  );
};
export default SelectFilter;
