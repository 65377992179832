import React from "react";
import styles from "./styles.module.scss";
import SVG from "react-inlinesvg";
import Image from "next/image";
export const ChipSelect = ({
  value,
  selectedValue,
  required,
  disabled,
  onChange,
  className,
  label,
  labelcss,
  iconUrl,
  iconHeight,
  iconWidth,
  iconType,
  checkedImage,
  ...props
}) => {
  const hiddenRadioInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenRadioInput.current.click();
  };
  const onchangeRadioClick = (event) => {
    onChange(event.target.value);
  };
  return (
    <div className={`${className}`}>
      <div
        className={`${styles.radioButton} d-flex ${labelcss} ${
          selectedValue === value ? styles.checked : ""
        }`}
        onClick={handleClick}
      >
        {/* {iconType === "svg" ? (
          <SVG
            src={iconUrl ?? ""}
            width={iconWidth ?? "20px"}
            height={iconHeight ?? "20px"}
          />
        ) : (
          <Image
            src={
              selectedValue === value
                ? checkedImage ?? iconUrl ?? ""
                : iconUrl ?? ""
            }
            width={iconWidth ?? "20px"}
            height={iconHeight ?? "20px"}
            alt="radiobutton"
          />
        )} */}
        <Image
            src={
              selectedValue === value
                ? checkedImage ?? iconUrl ?? ""
                : iconUrl ?? ""
            }
            width={iconWidth ?? "20px"}
            height={iconHeight ?? "20px"}
            alt="radiobutton"
            layout="fixed"
          />
        <p>{label}</p>
      </div>
      <input
        ref={hiddenRadioInput}
        type="radio"
        checked={selectedValue === value}
        value={value}
        onChange={(e) => onchangeRadioClick(e)}
        className={`d-none`}
        disabled={disabled ?? false}
        required={required ?? false}
      ></input>
    </div>
  );
};
